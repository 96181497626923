import React, { useContext } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "@components/mixins"
import Layout from "@components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import { Typography, Grid, CardActionArea } from "@material-ui/core"
import { styles, functions } from './styles/card.js'
import { IntlContextConsumer, navigate, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../../heplers"
import Seo from "@components/seo"
import { graphql } from "gatsby"

const Index = ({ pageContext, data }) => {
  const {
    photo_header,
    img_container,
    img_box,
  } = makeComponentStyles(styles);

  const { language } = useContext(IntlContextConsumer);
  const intl = useIntl();

  const payload = pageContext.payload.map(node => ({
    id: node.id,
    node: getStrapiContentByLang(node.nodes, language)
  }));

  const {
    GallerySEO,
  } = getStrapiContentByLang(data.allStrapiSettings.edges, intl.locale);

  return (
    <div>
      <Seo title={GallerySEO?.TitleSEO}
           description={GallerySEO?.DescriptionSEO}
           keywords={GallerySEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          <Container>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {intl.formatMessage({ id: 'photogallery' })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <Grid container spacing={3}>
                  {
                    payload.map(({ id, node }, key) => (
                      <Grid
                        item xs={12} sm={6} md={4} key={key}
                        className={img_container} >
                        <CardActionArea
                          onClick={() => navigate(`/photo_gallery/${id}`)}
                          className={clsx(img_box, functions.makeImage(node.image?.localFile?.publicURL))}
                        >
                          <Typography className={photo_header}>{node.title}</Typography>
                        </CardActionArea>
                      </Grid>
                    ))
                  }
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Layout>
      </>
    </div>
  )
}

export default Index

export const pageQuery = graphql`{
  allStrapiSettings {
    edges {
      node {
        GallerySEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
        locale
      }
    }
  }
}`
